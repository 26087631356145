<template>
    <BaseSection>
        <div class="card mt-4" v-if="userSubscriptionConfirmed">
            <div class="card-body text-center">
                <div class="row justify-content-center">
                    <div class="col-12 col-sm-6">
                        <img class="w-50 mb-4" src="@/assets/rocket.svg" />
                        <h4>Thank you for confirming your subscription!</h4>
                        <p>We'll let you know as soon as we are up and running!</p>
                    </div>
                </div>
            </div>
        </div>
    </BaseSection>
</template>

<script>
import { ROUTE } from '@/enums'
import { BaseSection } from '@/components'
import { createNamespacedHelpers } from 'vuex'

const { mapState, mapActions } = createNamespacedHelpers('subscriptions')

export default {
    name: 'ConfirmSubscription',

    components: { BaseSection },

    computed: {
        ...mapState(['userSubscriptionConfirmed']),
    },

    methods: {
        ...mapActions(['confirmSubscription']),
    },

    async mounted() {
        await this.confirmSubscription({
            sId: this.$route.query.sid,
            kId: this.$route.query.kid,
        })

        if (!this.userSubscriptionConfirmed) this.$router.push({ name: ROUTE.START })
    },
}
</script>
